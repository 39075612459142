export default [
    {
        path: '/admin/photos',
        name: 'admin-photos',
        component: () => import('@/modules/Admin/Pages/PhotosPage.vue'),
        meta: {
            parent: "admin",
            pageTitle: 'admin.photos.title',
            breadcrumb: [
                { title: 'admin.photos.title', url: `/admin/photos`, active: true }
            ]
        },
    },
]
