import store from "@/modules/Shared/Store/store";
import i18n from "@/i18n";

export default [
    {
        path: '/admin/challenges',
        name: 'admin-challenges',
        component: () => import('@/modules/Admin/Pages/ChallengesPage.vue'),
        meta: {
            parent: "admin",
            pageTitle: 'admin.challenges.title',
            breadcrumb: [
                { title: 'admin.challenges.title', url: `/admin/challenges`, active: true }
            ]
        },
    },
    {
        path: '/admin/challenges/:uuid/settings',
        name: 'admin-challenges-settings',
        component: () => import('@/modules/Admin/Pages/ChallengeSettingsPage.vue'),
        meta: {
            parent: "admin",
            pageTitle: 'admin.challenges.settings.title',
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'admin.challenges.title', url: `/admin/challenges` },
                {
                    title: () => {
                        const challenge = store.getters.routeElement
                        return challenge ? `${i18n.t('datatable.button.edit')} ${challenge.title}` : `${i18n.t('shared.add')}`
                    },
                    active: true
                },
            ]
        },
    },
    {
        path: '/admin/challenges/:uuid/translations',
        name: 'admin-challenge-translation',
        component: () => import('@/modules/Admin/Pages/ChallengeTranslationsPage.vue'),
        meta: {
            parent: "admin",
            pageTitle: 'admin.challenges.translations.title',
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'admin.challenges.title', url: `/admin/challenges` },
                {
                    title: () => {
                        const challenge = store.getters.routeElement
                        return `${i18n.t('datatable.button.edit')} ${challenge.title}`
                    },
                    active: true
                },
            ]
        },
    },
    {
        path: '/admin/challenges/:uuid/ranking',
        name: 'admin-challenge-ranking',
        component: () => import('@/modules/Admin/Pages/ChallengeRankingPage.vue'),
        meta: {
            parent: "admin",
            pageTitle: 'admin.challenges.ranking.title',
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'admin.challenges.title', url: `/admin/challenges` },
                {
                    title: () => {
                        const challenge = store.getters.routeElement
                        return `${challenge.title} ${i18n.t('resorts.leaderboards.title')}`
                    },
                    active: true
                },
            ]
        },
    },
]
