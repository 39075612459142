import store from "@/modules/Shared/Store/store";
import i18n from "@/i18n";

export default [
    {
        path: '/admin/tracks',
        name: 'admin-tracks',
        component: () => import('@/modules/Admin/Pages/TracksPage.vue'),
        meta: {
            parent: "admin",
            pageTitle: 'apps.tracks.title',
            breadcrumb: [
                { title: 'apps.tracks.title', url: `/admin/tracks`, active: true }
            ]
        },
    },
    {
        path: '/admin/tracks/:uuid/validation',
        name: 'admin-track-validation',
        component: () => import('@/modules/Admin/Pages/EditTrackValidationPage.vue'),
        props: (route) => ({
            ...route.params
        }),
        meta: {
            parent: "admin",
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'apps.tracks.title', url: `/admin/tracks`},
                {
                    title: () => {
                        const trackValidation = store.getters.routeElement
                        return `${i18n.t('admin.tracks.track')} ${trackValidation.id} ${i18n.t('admin.tracks.validation.validation')}`
                    },
                    active: true
                },
            ]
        },
    },
]
