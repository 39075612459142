// import Vue from 'vue'
import i18n from "@/i18n";

const notifications = {
  data() {
    return {
      iconPack: "feather",
      position: "top-right"
    }
  },
  methods: {
    notifySuccess(title = i18n.t("shops.success"), text = null) {
      this.$vs.notify({
        title,
        text,
        iconPack: this.iconPack,
        icon: "icon-check",
        color: "success",
        position: this.position
      })
    },
    notifyError(title = i18n.t("shops.error.0"), text = null, time = 2000) {
      this.$vs.notify({
        title,
        text,
        iconPack: this.iconPack,
        icon: "icon-alert-circle",
        color: "danger",
        position: this.position,
        time: time
      });
    },
    notifyWarning(title = i18n.t("shops.warning"), text = null) {
      this.$vs.notify({
        title,
        text,
        iconPack: this.iconPack,
        icon: "icon-alert-circle",
        color: "danger",
        position: this.position
      });
    }
  }
}

export default notifications

// If this is uncommented, notifications will be loaded to EVERY component that gets instanced.
// Ref: https://vuejs.org/v2/guide/mixins.html#Global-Mixin
// Vue.mixin(notifications)
